/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/apps/common/js/jquery/aacom/plugins/aaPhoneCountryCode.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/legacy/plugins/aa-phone-country-code.js
**/

/*
 * aaPhoneCountryCode.js
 */
var aaPhoneCountryCode = new(function() {
    var self = this; //public elements

    //Private variables
    var _module = '.aaPhoneCountryCode';
    var _selectSize = 'select[size]';
    var _selectRead = 'select[readonly]';
    var _selectBoxSize = _module + ' ' + _selectSize;
    var _selectBoxRead = _module + ' ' + _selectRead;
    var _activeClass = '-active';
    var _focusClass = '-focus';

    //Private methods
    var _bindFocusEvent = function() {
        jQuery(document).on('focus', _selectBoxRead, function() {
            jQuery(this).parents(_module).find(_selectSize).click().focus();
            jQuery(this).find('option').hide();
        });
        jQuery(document).on('focus', _selectBoxSize, function() {
            jQuery(this).parents(_module).addClass(_focusClass).addClass(_activeClass);
        });
    };
    var _bindSelectEvent = function() {
        jQuery(document).on('blur change', _selectBoxSize, function(ev) {
            _displaySelectedOption(this,ev);
        });
    };
    var _bindBlurEvent = function() {
        jQuery(document).on('blur', _selectBoxSize, function() {
            jQuery(this).parents(_module).removeClass(_focusClass).removeClass(_activeClass);
        });
    };
    var _bindClickEvent = function() {
        jQuery(document).on('click', _selectBoxSize, function() {
            jQuery(this).parents(_module).removeClass(_activeClass);
        });
    };
    var _bindKeyEvent = function() {
        jQuery(document).on('keydown', _selectBoxSize, function(ev) {
            var $module = jQuery(this).parents(_module),
                escKey = 27, enterKey = 13, spaceKey = 32, upKey = 38, downKey = 40;
            if (ev.keyCode === escKey || ev.keyCode === enterKey) {
                if ($module.hasClass(_activeClass)) {
                    $module.removeClass(_activeClass);
                    return false;
                }
            }
            if (ev.keyCode === upKey || ev.keyCode === downKey) {
                if (!$module.hasClass(_activeClass)) {
                    $module.addClass(_activeClass);
                    return false;
                }
            }
        });
    };
    var _displaySelectedOption = function(obj,event) {
        var $this = jQuery(obj);
        var $selected = $this.find('option:selected');
        if ($selected.length === 0) {
            $selected = $this.find('option:first');
        }
        var option = '<option value="' + $selected.val() + '" selected>' + $selected.attr('data-shortname') + '</option>';
        var $selectRead = $this.parents(_module).find(_selectRead);
        $selectRead.find('option').remove();
        $selectRead.append(option);
        _formatPhoneNumber(obj,event);
    };
    var _formatPhoneNumber = function(obj,event) {
        var $this = jQuery(obj);
        var $selected = $this.find('option:selected');
        var $phone = $this.parents('fieldset[class*="input-group"]:first').find('.js-phoneNumber');
        var $phoneExt = $this.parents('fieldset[class*="input-group"]:first').find('.js-phoneExt');
        var country = $selected.val();
        var phoneLength = {US:"10", PR:"10", CA:"10", VI:"10", ES:"9", 'default':"15"};
        var length = phoneLength[country] || phoneLength['default'];
        if (jQuery('html').hasClass('lt-ie9') === false) {
            try {
                $phone.attr('type','tel');
            } catch(e) {}
        }
        $phone.attr('maxlength',length);
        if (event !== undefined && event.type === "change") {
            $phone.val('');
            $phoneExt.val('');
        }
    };

    //Public methods
    self.initSelectBox = function() {
        var _mod = _module.replace('.', '');
        var $module = jQuery('select[data-behavior="' + _mod + '"]');
        $module.each(function() {
            var $clone = jQuery(this).clone();
            var length = jQuery(this).find('option').length;
            jQuery(this).wrapAll('<div class="' + _mod + ' aaMaterialCountryCodes"></div>');
            jQuery(this).removeAttr('data-behavior');
            jQuery(this).attr('size', length > 10 ? 10 : length);
            jQuery(this).find('option').each(function() {
                jQuery(this).attr('data-shortname', jQuery(this).text());
                jQuery(this).text(jQuery(this).data('longname'));
                jQuery(this).removeAttr('data-longname');
            });
            var cloneName = $clone.attr('id') + 'Readonly';
            $clone.attr('readonly', 'readonly').attr('tabindex', '-1').attr('name', cloneName).attr('id', cloneName);
            $clone.find('option').remove();
            jQuery(this).before($clone).addClass('selectbox-label');
            _displaySelectedOption(this);
        });
    };

    self.initLabelBox = function() {
        var _mod = _module.replace('.','');
        var $module = jQuery('label[data-behavior="'+_mod+'"]');
        $module.each(function(){
            var $clone = jQuery(this).clone();
            var length = jQuery(this).find('option').length;
            // jQuery(this).wrapAll('<div class="'+_mod+'"></div>');
            jQuery(this).removeAttr('data-behavior');
            // jQuery(this).addClass('selectbox-label');
            // jQuery(this).find('select').attr('size',length > 10 ? 10 : length);
            jQuery(this).find('option').each(function(index, value) {
                var longname = jQuery(this).data('longname');
                if ( index > 0 ) {
                    // jQuery(this).attr('data-shortname',jQuery(this).text());
                    var name = longname.slice(0, longname.indexOf('+'));
                    longname = jQuery(this).text() + " - " + name;
                }
                jQuery(this).text(longname);
                jQuery(this).removeAttr('data-longname');
            });
            // var cloneName = $clone.attr('for')+'Readonly';
            // $clone.removeAttr('data-behavior');
            // $clone.find('select').before('<div class="selectbox-overlay"></div>');
            // $clone.find('select').attr('readonly','readonly');
            // $clone.find('select').attr('tabindex','-1');
            // $clone.find('option').remove();
            // $clone.attr('for',cloneName);
            // $clone.find('select').attr('name',cloneName);
            // $clone.find('select').attr('id',cloneName);
            // jQuery(this).before($clone);
            _displaySelectedOption(this);
        });
    };

    self.init = function(once) {
        once = once || false;
        self.initSelectBox();
        self.initLabelBox();
        if (once) {
            //_bindFocusEvent();
            //_bindSelectEvent();
            //_bindBlurEvent();
            _bindClickEvent();
            _bindKeyEvent();
        }
    };

    //document ready initialization
    jQuery(document).ready(function() {
        self.init(true);
    });
});
